const c = [
	() => import("../../../src/routes/__layout.svelte"),
	() => import("../components/error.svelte"),
	() => import("../../../src/routes/index.svelte"),
	() => import("../../../src/routes/settings.svelte"),
	() => import("../../../src/routes/gojiras.svelte"),
	() => import("../../../src/routes/prepare.svelte"),
	() => import("../../../src/routes/fight.svelte")
];

const d = decodeURIComponent;

export const routes = [
	// src/routes/index.svelte
	[/^\/$/, [c[0], c[2]], [c[1]]],

	// src/routes/settings.svelte
	[/^\/settings\/?$/, [c[0], c[3]], [c[1]]],

	// src/routes/gojiras.svelte
	[/^\/gojiras\/?$/, [c[0], c[4]], [c[1]]],

	// src/routes/prepare.svelte
	[/^\/prepare\/?$/, [c[0], c[5]], [c[1]]],

	// src/routes/fight.svelte
	[/^\/fight\/?$/, [c[0], c[6]], [c[1]]]
];

// we import the root layout/error components eagerly, so that
// connectivity errors after initialisation don't nuke the app
export const fallback = [c[0](), c[1]()];